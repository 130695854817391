import * as React from "react"
import {
  DashedLine,
  ProgressLine,
  SlidePointer,
  SlidePointerBackground,
  SlidePointerContainer,
  SliderContainer,
  SliderMarker,
  SlideText
} from "./styled"

const SLIDE_DURATION = 10000

interface CarouselProps {
  markerTexts: React.ReactNode[]
}

interface CarouselState {
  slideIndex: number
  progress: number
}

export default class Carousel extends React.Component<CarouselProps, CarouselState> {
  state: CarouselState = {
    slideIndex: 0,
    progress: 0
  }

  private intervalId: number | undefined
  private progressId: number | undefined

  goToNext = () => {
    const { slideIndex } = this.state
    const slides = this.props.children as React.ReactNode[]

    let updatedSlideIndex = slideIndex + 1
    if (slideIndex === slides.length - 1) {
      updatedSlideIndex = 0
    }

    this.setState(prevState => ({
      slideIndex: updatedSlideIndex
    }))
  }

  goToPrevious = () => {
    const { slideIndex } = this.state
    const slides = this.props.children as React.ReactNode[]

    let updatedSlideIndex = slideIndex - 1
    if (slideIndex === 0) {
      updatedSlideIndex = slides.length - 1
    }

    this.setState(prevState => ({
      slideIndex: updatedSlideIndex
    }))
  }

  viewSliderNumber = (slideNumber: number) => () => {
    const slides = this.props.children as React.ReactNode[]

    const progress = slideNumber * 100 / (slides.length - 1)

    if (this.progressId) {
      window.clearInterval(this.progressId)
      this.progressId = undefined
    }

    if (this.intervalId) {
      window.clearInterval(this.intervalId)
      this.intervalId = undefined
    }

    this.setState(prevState => ({
      slideIndex: slideNumber,
      progress
    }), () => {
      this.intervalId = window.setInterval(this.goToNext, SLIDE_DURATION)
      this.progressId = window.setInterval(this.updateProgress, 100)
    })
  }

  updateProgress = () => {
    const { progress, slideIndex } = this.state
    const slides = this.props.children as React.ReactNode[]

    if (progress > 99.9) {
      if (slideIndex !== slides.length - 1) {
        this.setState(prevState => ({
          progress: 0
        }))
      }

      return
    }

    const delta = 100 * 100 / ((slides.length - 1) * SLIDE_DURATION)

    this.setState(prevState => ({
      progress: progress + delta
    }))
  }

  componentDidMount() {
    this.intervalId = window.setInterval(this.goToNext, SLIDE_DURATION)
    this.progressId = window.setInterval(this.updateProgress, 100)
  }

  componentWillUnmount() {
    if (this.intervalId) {
      window.clearInterval(this.intervalId)
      this.intervalId = undefined
    }

    if (this.progressId) {
      window.clearInterval(this.progressId)
      this.progressId = undefined
    }
  }

  render() {
    const { slideIndex, progress } = this.state
    const { markerTexts } = this.props
    const slides = this.props.children as React.ReactNode[]

    return (
      <>
        <SliderContainer>
          <DashedLine />
          <ProgressLine style={{ width: `${ progress }%` }} />

          {slides.map((_, index) => {

            const isActiveSlide = slideIndex === index
            return (
              <SliderMarker key={index} onClick={this.viewSliderNumber(index)}>
                <SlidePointerContainer>
                  <SlidePointerBackground active={isActiveSlide}>
                    <SlidePointer />
                  </SlidePointerBackground>
                </SlidePointerContainer>

                <SlideText active={isActiveSlide}>
                  {markerTexts[index]}
                </SlideText>
              </SliderMarker>
            )
          })}
        </SliderContainer>

        {slides[slideIndex]}
      </>
    )
  }
}