import { G500 } from "@stackchat/colors"
import hero from "assets/images/bot-builder-hero.png"
import hero2x from "assets/images/bot-builder-hero@2x.png"
import hero3x from "assets/images/bot-builder-hero@3x.png"
import hero4x from "assets/images/bot-builder-hero@4x.png"
import hero5x from "assets/images/bot-builder-hero@5x.png"
import multilingualText from "assets/png/multilingual.png"
import slide1 from "assets/png/slide-1.png"
import slide2 from "assets/png/slide-2.png"
import slide3 from "assets/png/slide-3.png"
import slide4 from "assets/png/slide-4.png"
import adobeLogo from "assets/svg/adobe.svg"
import aiSmarts from "assets/svg/bot-builder-ai-smarts.svg"
import greenTriangle from "assets/svg/bot-builder-green-triangle.svg"
import publishChannels from "assets/svg/bot-builder-publish-channels.svg"
import purpleTriangle from "assets/svg/bot-builder-purple-triangle.svg"
import userVoice from "assets/svg/bot-builder-user-voice.svg"
import integrations from "assets/svg/company-integrations.svg"
import enterpriseSecurityBg from "assets/svg/enterprise-bg.svg"
import wave from "assets/svg/wave.svg"
import BackgroundContainer from "components/bg-container"
import Button from "components/button"
import Carousel from "components/carousel"
import { A, DividedSection, H1, H2, H3, Image, P, SpecialText } from "components/design-system"
import HubspotForm from "components/hubspot-form"
import Section, { Column } from "components/new-section"
import { studioLinkPrefix } from "config/link"
import { locale } from "config/locale"
import { Link } from "gatsby"
import * as React from "react"
import { FormattedMessage } from "react-intl"
import styled from "styled-components"
import Layout from "templates/layout"

const VideoContainer = styled.div`
  margin-top: -20%;
  box-shadow: 0 2px 4px 2px #d8d8d8;
  border-radius: 4px;
  overflow: hidden;
  max-height: 140%;
  display: flex;
  align-items: center;
`

const CustomImage = styled(Image)`
  border-radius: 4px;
  box-shadow: 0 0 4px 1px #d8d8d8;
`

export default class BotBuilder extends React.Component {
  render() {
    return (
      <Layout title="Bot Builder | Stackchat">

        {/* Hero Section */}
        <BackgroundContainer
          image={[
            `url('${ hero5x }')`,
            `url('${ hero4x }')`,
            `url('${ hero3x }')`,
            `url('${ hero2x }')`,
            `url('${ hero }')`
          ]}
          classes="N10-bg bot-builder-hero"
          style={{
            backgroundSize: "cover",
            backgroundPosition: "bottom 0 left 0"
          }}
        >
          <Section partition="full-width">
            <Column className="col-12 col-md-10">
              <H2 className="N0 space-outer-top-8" style={{ marginBottom: 0 }}>
                <FormattedMessage id="bot-builder.hero.title.part1" />
              </H2>
              <H2 className="N0">
                <FormattedMessage id="bot-builder.hero.title.part2" />
              </H2>
            </Column>
          </Section>

          <Section partition="full-width">
            <Column className="col-12 col-md-6">
              <P className="N0">
                <FormattedMessage id="bot-builder.hero.description" />
              </P>

              <A
                href={`${ studioLinkPrefix + (locale === "zh" ? "" : "/account/register") }`}
                target="_blank"
              >
                <Button
                  title={<FormattedMessage id="home.hero.link" />}
                  className="large space-outer-top-6"
                  inverse={true}
                />
              </A>
            </Column>
          </Section>
        </BackgroundContainer>

        {/* Build Voice Seach */}
        <Section
          partition="full-width"
          classes="N10-bg bot-builder-voice-search"
        >
          <Column className="col-12 text-center voice">
            <img src={wave} width="88px" />

            <H3 style={{ fontWeight: 900 }}>
              <FormattedMessage id="bot-builder.section-2.title" />
            </H3>

            <P style={{ marginBottom: 88 }}>
              <FormattedMessage id="bot-builder.section-2.description" />
            </P>
          </Column>
        </Section>

        {/* Video */}
        <Section
          partition="full-width"
        >
          <Column className="text-center col-12 col-xl-10 offset-xl-1">
            <VideoContainer>
              <video
                controls={false}
                // this is required for mobile safari
                playsInline={true}
                autoPlay={true}
                loop={true}
                muted={true}
                src="/Demo.mp4"
                style={{
                  width: "100%"
                }}
              />
            </VideoContainer>
          </Column>
        </Section>

        {/* Step 1 */}
        <Section
          partition="full-width"
          classes="space-inner-top-10"
        >
          <Column className="col-12 text-center">
            <div className="bot-builder-step-number">
              <SpecialText>
                <FormattedMessage id="bot-builder.step1.label" />
              </SpecialText>
            </div>

            <div className="bot-builder-step-one-title">
              <H1>
                <FormattedMessage id="bot-builder.step1.title" />
              </H1>
            </div>
          </Column>

          <Column className="col-12">
            <Carousel
              markerTexts={[
                <FormattedMessage key={1} id="bot-builder.step1.slide1.marker-text" />,
                <FormattedMessage key={2} id="bot-builder.step1.slide2.marker-text" />,
                <FormattedMessage key={3} id="bot-builder.step1.slide3.marker-text" />,
                <FormattedMessage key={4} id="bot-builder.step1.slide4.marker-text" />
              ]}
            >
              <div className="row">
                <Column className="col-12 col-md-6 d-flex flex-column justify-content-center">
                  <P className="slide-text">
                    <FormattedMessage id="bot-builder.step1.slide1.text" />
                  </P>
                </Column>

                <Column className="col-12 col-md-6">
                  <CustomImage src={slide1} />
                </Column>
              </div>
              <div className="row">
                <Column className="col-12 col-md-6 d-flex flex-column justify-content-center">
                  <P className="slide-text">
                    <FormattedMessage id="bot-builder.step1.slide2.text" />
                  </P>
                </Column>

                <Column className="col-12 col-md-6">
                  <CustomImage src={slide2} />
                </Column>
              </div>
              <div className="row">
                <Column className="col-12 col-md-6 d-flex flex-column justify-content-center">
                  <P className="slide-text">
                    <FormattedMessage id="bot-builder.step1.slide3.text" />
                  </P>
                </Column>

                <Column className="col-12 col-md-6">
                  <CustomImage src={slide3} />
                </Column>
              </div>
              <div className="row">
                <Column className="col-12 col-md-6 d-flex flex-column justify-content-center">
                  <P className="slide-text">
                    <FormattedMessage id="bot-builder.step1.slide4.text" />
                  </P>
                </Column>

                <Column className="col-12 col-md-6">
                  <CustomImage src={slide4} />
                </Column>
              </div>
            </Carousel>
          </Column>
        </Section>

        {/* Step 2 */}
        <Section
          partition="full-width"
          classes="space-inner-top-10 space-outer-top-4"
          style={{
            backgroundColor: "#f8f9fc"
          }}
        >
          <Column className="col-12 text-center">
            <div className="bot-builder-step-number">
              <SpecialText>
                <FormattedMessage id="bot-builder.step2.label" />
              </SpecialText>
            </div>

            <div className="bot-builder-step-two-title">
              <H1>
                <FormattedMessage id="bot-builder.step2.title" />
              </H1>
            </div>
          </Column>

          <Column className="col-12 space-outer-top-4" />
        </Section>

        {/* Add AI Smarts */}
        <Section
          partition="60-40"
          classes="space-inner-top-3 space-inner-bottom-3"
          style={{
            backgroundColor: "#f8f9fc"
          }}
          inverse={true}
          leftChild={(
            <Image src={aiSmarts} width="80%" />
          )}
          rightChild={(
            <DividedSection
              image=""
              left={true}
              className="d-flex flex-column justify-content-center">
              <H3>
                <FormattedMessage id="bot-builder.step2.ai-smarts.title" />
              </H3>

              <P className="space-outer-top-1">
                <FormattedMessage id="bot-builder.step2.ai-smarts.description" />
              </P>
            </DividedSection>
          )}
        />

        {/* Give users voice */}
        <Section
          classes="space-inner-top-6"
          style={{
            clipPath: "polygon(0 0, 100% 0, 100% 70%, 78% 70%, 59% 83%, 31% 85%, 13% 100%, 0 95%)",
            paddingBottom: 200,
            backgroundColor: "#f8f9fc"
          }}
          partition="60-40"
          leftChild={(
            <Image src={userVoice} width="80%" />
          )}
          rightChild={(
            <DividedSection
              image=""
              className="d-flex flex-column justify-content-center">
              <H3>
                <FormattedMessage id="bot-builder.step2.user-voice.title" />
              </H3>

              <P className="space-outer-top-1">
                <FormattedMessage id="bot-builder.step2.user-voice.description" />
              </P>
            </DividedSection>
          )}
        />


        {/* Step 3 */}
        <Section
          partition="full-width"
          classes="space-inner-top-6"
        >
          <Column className="col-12 text-center">
            <div className="bot-builder-step-number">
              <SpecialText>
                <FormattedMessage id="bot-builder.step3.label" />
              </SpecialText>
            </div>

            <div className="bot-builder-step-three-title">
              <H1>
                <FormattedMessage id="bot-builder.step3.title" />
              </H1>
            </div>

            <H3>
              <FormattedMessage id="bot-builder.step3.description1" />
            </H3>

            <P className="space-outer-top-4">
              <FormattedMessage id="bot-builder.step3.description2" />
            </P>

            <Image className="space-outer-top-8" src={publishChannels} />
          </Column>
        </Section>

        {/* Ready to Get Started */}
        <Section
          partition="full-width"
          classes="space-outer-top-10 space-inner-bottom-4 space-outer-bottom-4 N10-bg"
          style={{
            clipPath: "polygon(17% 1%, 39% 9%, 67% 0, 77% 15%, 100% 6%, 100% 100%, 0 100%, 0 10%)",
            paddingTop: 120,
            backgroundColor: "#f8f9fc"
          }}
        >
          <Column className="col-12 text-center">
            <H1 className="space-outer-bottom-0">
              <FormattedMessage id="home.cta2.heading" />
            </H1>

            <P>
              <FormattedMessage id="home.cta2.description" />
            </P>
          </Column>

          <Column
            className="col-12 space-outer-top-3 space-outer-bottom-3"
          >
            <div
              style={{ maxWidth: 400, marginLeft: "auto", marginRight: "auto" }}
              className="d-flex flex-column flex-sm-row justify-content-between"
            >
              <Link to="/contact">
                <Button
                  title={<FormattedMessage id="home.cta2.link1" />}
                  className="large space-outer-top-1 space-outer-bottom-1"
                />
              </Link>

              <A
                href={`${ studioLinkPrefix + (locale === "zh" ? "" : "/account/register") }`}
                target="_blank"
                style={{ border: "none" }}
              >
                <Button
                  title={<FormattedMessage id="home.cta2.link2" />}
                  className="large space-outer-top-1 space-outer-bottom-1"
                  hollow={true}
                />
              </A>
            </div>
          </Column>
        </Section>

        {/* Multilingual */}
        <Section
          partition="60-40"
          classes="space-inner-top-10 space-inner-bottom-10"
          inverse={true}
          leftChild={(
            <div className="d-flex flex-column justify-content-center" style={{ height: "100%" }}>
              <Image src={multilingualText} className="space-outer-top-2" />
            </div>
          )}
          rightChild={(
            <DividedSection
              left={true}
              image=""
              className="d-flex flex-column justify-content-center">
              <H1 className="space-outer-top-0 space-outer-bottom-2">
                <FormattedMessage id="enterprise.section11.heading" />
              </H1>

              <P>
                <FormattedMessage id="enterprise.section11.description" />
              </P>
            </DividedSection>
          )}
        />

        {/* Integrations */}
        <Section
          classes="space-inner-top-10 space-inner-bottom-10"
          partition="60-40"
          leftChild={(
            <div
              style={{ height: "100%" }}
              className="space-inner-top-6 space-inner-bottom-6"
            >
              <Image src={integrations} />
            </div>
          )}
          rightChild={(
            <DividedSection
              image=""
              className="d-flex flex-column justify-content-center"
            >
              <H1 className="space-outer-top-0 space-outer-bottom-2">
                <FormattedMessage id="enterprise.section12.heading" />
              </H1>

              <P>
                <FormattedMessage id="enterprise.section12.description" />
              </P>
            </DividedSection>
          )}
        />

        {/* Adobe Experience Cloud */}
        <BackgroundContainer
          image={`url('${ enterpriseSecurityBg }')`}
          classes="space-inner-top-6 space-inner-bottom-6"
          style={{
            backgroundSize: "cover",
            backgroundPosition: "50% 50%"
          }}
        >
          <Section
            partition="full-width"
            classes="text-center"
          >
            <Column className="col-12">
              <H2 className="N0 space-outer-bottom-0">
                <FormattedMessage id="enterprise.section13.heading" />
              </H2>

              <P className="N0">
                <FormattedMessage id="enterprise.section13.description" />
              </P>

              <Image
                src={adobeLogo}
                className="space-outer-top-4"
                style={{ maxWidth: 320 }}
              />

              {/* <MainLink
                classes="N0"
                text="enterprise.section13.link"
                link="#"
              /> */}
            </Column>
          </Section>
        </BackgroundContainer>

        {/* Subscribe */}
        <BackgroundContainer
          image={`url('${ greenTriangle }')`}
          style={{
            backgroundPosition: "8% 32%",
            backgroundSize: "88px auto"
          }}
          hideBackgroundOnMobile={true}
        >
          <BackgroundContainer
            image={`url('${ purpleTriangle }')`}
            style={{
              backgroundPosition: "bottom 4% right 8%",
              backgroundSize: "88px auto"
            }}
            hideBackgroundOnMobile={true}
          >
            <Section
              partition="full-width"
              classes="space-inner-top-4 space-inner-bottom-4"
            >
              <Column className="col-12 d-flex flex-column align-items-center text-center">
                <H1 className="P700 space-outer-bottom-0">
                  <FormattedMessage id="home.subscribe.heading" />
                </H1>

                <P className="N800" style={{ maxWidth: 500 }}>
                  <FormattedMessage id="home.subscribe.description" />
                </P>

                <HubspotForm
                  light={false}
                  portalId="3371700"
                  formId="9b8a3d5c-0ca2-415a-9403-d8de927e237e"
                  color={G500}
                />
              </Column>
            </Section>
          </BackgroundContainer>
        </BackgroundContainer>
      </Layout>
    )
  }
}