import { N0, N50, P100, P700 } from "@stackchat/colors"
import styled, { css } from "styled-components"

export const SliderContainer = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: 32px;
  overflow: hidden;
`

export const SlidePointerContainer = styled.div`
  height: 48px;
  width: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${ N0 };
  padding-top: 4px;
  flex-shrink: 0;
  z-index: 2;

  @media only screen and (max-width: 575.99px) {
    width: 32px;
    height: 32px;
  }
`

const getSliderPointerBackgroundStyles =
  ({ active }: { active: boolean }) => css`
    width: 36px;
    height: 36px;
    background-color: ${ active ? P100 : N0 };
    border: 2px solid ${ P100 };
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 250ms linear;
    cursor: ${ active ? "default" : "pointer" };

    @media only screen and (max-width: 575.99px) {
      width: 24px;
      height: 24px;
      border-width: 1px;
    }
  `

export const SlidePointerBackground = styled.div`
  ${ getSliderPointerBackgroundStyles }
`

export const SlidePointer = styled.div`
  width: 12px;
  height: 12px;
  background-color: ${ P700 };
  border-radius: 50%;

  @media only screen and (max-width: 575.99px) {
    width: 8px;
    height: 8px;
  }
`

export const SliderMarker = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

const getSlideTextStyles = ({ active }: { active: boolean }) => css`
  width: 160px;
  font-weight: 700;
  margin: 0;
  color: ${ active ? P700 : N50 };
  text-align: center;
  margin-top: 8px;
  line-height: 18px;
  cursor: ${ active ? "default" : "pointer" };

  @media only screen and (max-width: 575.99px) {
    display: none;
  }
`

export const SlideText = styled.p`
  ${ getSlideTextStyles }
`

export const DashedLine = styled.hr`
  border-top: 2px dashed ${ P100 };
  position: absolute;
  top: 23px;
  left: 57px;
  right: 57px;
  margin: 0;
  z-index: 1;

  @media only screen and (max-width: 575.99px) {
    border-width: 1px;
    top: 50.25%;
    left: 0;
    right: 0;
  }
`

export const ProgressLine = styled.hr`
  border-top: 2px solid ${ P700 };
  position: absolute;
  top: 23px;
  left: 57px;
  max-width: calc(100% - 114px);
  margin: 0;
  z-index: 1;
  transition: all 250ms linear;

  @media only screen and (max-width: 575.99px) {
    border-width: 1px;
    top: 50.25%;
    left: 0;
    right: 0;
    max-width: 100%;
  }
`